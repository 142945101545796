
    <template>
      <section class="content element-doc">
        <h2>Badge 标记</h2>
<p>出现在按钮、图标旁的数字或状态标记。</p>
<h3>基础用法</h3>
<p>展示新消息数量。</p>
<demo-block>
        <div><p>定义<code>value</code>属性，它接受<code>Number</code>或者<code>String</code>。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-badge :value=&quot;12&quot; class=&quot;item&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;评论&lt;/el-button&gt;
&lt;/el-badge&gt;
&lt;el-badge :value=&quot;3&quot; class=&quot;item&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;回复&lt;/el-button&gt;
&lt;/el-badge&gt;
&lt;el-badge :value=&quot;1&quot; class=&quot;item&quot; type=&quot;primary&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;评论&lt;/el-button&gt;
&lt;/el-badge&gt;
&lt;el-badge :value=&quot;2&quot; class=&quot;item&quot; type=&quot;warning&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;回复&lt;/el-button&gt;
&lt;/el-badge&gt;

&lt;el-dropdown trigger=&quot;click&quot;&gt;
  &lt;span class=&quot;el-dropdown-link&quot;&gt;
    点我查看&lt;i class=&quot;el-icon-caret-bottom el-icon--right&quot;&gt;&lt;/i&gt;
  &lt;/span&gt;
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item class=&quot;clearfix&quot;&gt;
      评论
      &lt;el-badge class=&quot;mark&quot; :value=&quot;12&quot; /&gt;
    &lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item class=&quot;clearfix&quot;&gt;
      回复
      &lt;el-badge class=&quot;mark&quot; :value=&quot;3&quot; /&gt;
    &lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;style&gt;
  .item {
    margin-top: 10px;
    margin-right: 40px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>最大值</h3>
<p>可自定义最大值。</p>
<demo-block>
        <div><p>由<code>max</code>属性定义，它接受一个<code>Number</code>，需要注意的是，只有当<code>value</code>为<code>Number</code>时，它才会生效。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-badge :value=&quot;200&quot; :max=&quot;99&quot; class=&quot;item&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;评论&lt;/el-button&gt;
&lt;/el-badge&gt;
&lt;el-badge :value=&quot;100&quot; :max=&quot;10&quot; class=&quot;item&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;回复&lt;/el-button&gt;
&lt;/el-badge&gt;

&lt;style&gt;
  .item {
    margin-top: 10px;
    margin-right: 40px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>自定义内容</h3>
<p>可以显示数字以外的文本内容。</p>
<demo-block>
        <div><p>定义<code>value</code>为<code>String</code>类型是时可以用于显示自定义文本。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-badge value=&quot;new&quot; class=&quot;item&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;评论&lt;/el-button&gt;
&lt;/el-badge&gt;
&lt;el-badge value=&quot;hot&quot; class=&quot;item&quot;&gt;
  &lt;el-button size=&quot;small&quot;&gt;回复&lt;/el-button&gt;
&lt;/el-badge&gt;

&lt;style&gt;
  .item {
    margin-top: 10px;
    margin-right: 40px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>小红点</h3>
<p>以红点的形式标注需要关注的内容。</p>
<demo-block>
        <div><p>除了数字外，设置<code>is-dot</code>属性，它接受一个<code>Boolean</code>。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-badge is-dot class=&quot;item&quot;&gt;数据查询&lt;/el-badge&gt;
&lt;el-badge is-dot class=&quot;item&quot;&gt;
  &lt;el-button
    class=&quot;share-button&quot;
    icon=&quot;el-icon-share&quot;
    type=&quot;primary&quot;
  &gt;&lt;/el-button&gt;
&lt;/el-badge&gt;

&lt;style&gt;
  .item {
    margin-top: 10px;
    margin-right: 40px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>value</td>
<td>显示值</td>
<td>string, number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>max</td>
<td>最大值，超过最大值会显示 '{max}+'，要求 value 是 Number 类型</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>is-dot</td>
<td>小圆点</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>hidden</td>
<td>隐藏 badge</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>type</td>
<td>类型</td>
<td>string</td>
<td>primary / success / warning / danger / info</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_badge = _resolveComponent("el-badge")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_badge, {
      value: 12,
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("评论")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_badge, {
      value: 3,
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("回复")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_badge, {
      value: 1,
      class: "item",
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("评论")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_badge, {
      value: 2,
      class: "item",
      type: "warning"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("回复")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dropdown, { trigger: "click" }, {
      default: _withCtx(() => [
        _createVNode("span", { class: "el-dropdown-link" }, [
          _createTextVNode(" 点我查看"),
          _createVNode("i", { class: "el-icon-caret-bottom el-icon--right" })
        ]),
        _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, { class: "clearfix" }, {
              default: _withCtx(() => [
                _createTextVNode(" 评论 "),
                _createVNode(_component_el_badge, {
                  class: "mark",
                  value: 12
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, { class: "clearfix" }, {
              default: _withCtx(() => [
                _createTextVNode(" 回复 "),
                _createVNode(_component_el_badge, {
                  class: "mark",
                  value: 3
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_badge = _resolveComponent("el-badge")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_badge, {
      value: 200,
      max: 99,
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("评论")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_badge, {
      value: 100,
      max: 10,
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("回复")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_badge = _resolveComponent("el-badge")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_badge, {
      value: "new",
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("评论")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_badge, {
      value: "hot",
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("回复")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_badge = _resolveComponent("el-badge")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_badge, {
      "is-dot": "",
      class: "item"
    }, {
      default: _withCtx(() => [
        _createTextVNode("数据查询")
      ]),
      _: 1
    }),
    _createVNode(_component_el_badge, {
      "is-dot": "",
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          class: "share-button",
          icon: "el-icon-share",
          type: "primary"
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  